@import "/src/configurable/styles/variables.scss";

.lesson {
    position: relative;
    max-width: 550px;
    width: 90%;
    height: 310px;
    z-index: revert;

    div {
        width: 100%;
        height: 100%;
        background: white;
        border: solid 4px $secondaryColor;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-family: $fontFamily;
        transition: transform 0.7s;
        transform: scale(1);

        &:hover { 
            transform: scale(1.04);
        } 

        h1 {
            font-size: 38px;
            font-weight: bold;
            color: $secondaryColor;
            margin: 0;
            text-align: center;

            span {
                width: 20px;
                display: inline-block;
            }
        }

        h2 {
            font-size: 22px;
            text-align: center;
            font-weight: 400;
            width: 90%;
            color: $secondaryColor;
        }

        .completed {
            position: absolute;
            right: 0;
            top: 0;
            transform: translate(40%, -40%);
            height: 70px;
        }

        .symbols {
            height: 50px;
        }
    }
}

.lesson.locked div{
    filter: grayscale(100%);

    &:hover {
        transform: none;
    }
}

@media (max-width: 600px) {
    .lesson {
        div {
            h1 {
                font-size: 25px;
                text-align: center;
            }

            h2 {
                font-size: 17px;
            }

            .completed {
                height: 60px;
            }
            
            .symbols {
                height: 45px;
            }
        }
    }
}