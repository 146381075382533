@import "/src/configurable/styles/variables.scss";

.footer {
    width: 100%;
    height: 100px;
    background: $primaryColor;
    margin-top: 40px;
    position: absolute;
    bottom: 0;

    display: flex;
    flex-direction: row;
    justify-content: center;

    padding: 0 30px 0 30px;

    .holder {
        width: 100%;
        max-width: 1200px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        .footerBlock { 
            width: calc(100% - 100px);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;

            h2 {
                color: white;
                font-family: $fontFamily;   
                font-size: 22px;
                transition: all 0.6s;
                margin: 0;
                padding: 0;
                width: max-content;

                &:hover {
                    scale: 1.02;
                }
            }
        }

        img {
            transition: all 0.6s;
            height: 60px;

            &:hover {
                scale: 1.1;
            }
        }
    }
}

@media (max-width: 700px) {
    .footer {
        height: 130px;

        .holder {
            width: 400px;

            .footerblock { 
                width: calc(100% - 100px);
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-between;

                h2 {
                    color: white;
                    font-family: $fontFamily;   
                    font-size: 22px;
                    transition: all 0.6s;
                    margin: 0;
                    padding: 0;
                    width: 300px;

                    &:hover {
                        scale: 1.02;
                    }
                }
            }

            img {
                transition: all 0.6s;
                height: 60px;

                &:hover {
                    scale: 1.1;
                }
            }
        }
    }
}

@media (max-width: 450px) {
    .footer {
        height: 160px;

        .holder {
            width: 340px;
            justify-content: center;
            
            .footerBlock {
                justify-content: center;
                align-items: center;
                flex-direction: column;
                flex-wrap: nowrap;
            }

            img {
                display: none;
            }
        }
    }
}