
@import "/src/configurable/styles/variables.scss";

.user {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: calc(100vh - 200px);
    width: 100%;

    .avatar {
        border-radius: 50%;
        max-width: 230px;
        max-height: 230px;
        height: 80vw;
        width: 80vw;
        border: solid 5px $secondaryColor;
        margin: 70px 0 50px 0;
        overflow: hidden;
        background: rgb(255, 255, 255);
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            height: 100%;
            display: flex;
            transform: translate(0, 5%);
        }
    }

    .container {
        width: calc(100% - 20px);
        max-width: 800px;
        min-height: 300px;
        border: solid 3px $secondaryColor;
        text-align: center;
        background: white;
        margin-bottom: 40px;
        
        .title {
            font-family: $fontFamily;
            font-size: 50px;
            font-weight: lighter;
            color: $secondaryColor;
        }
    }

    .button {
        background: $secondaryColor;
        margin: 30px auto 30px auto;
        padding: 7px 30px 7px 30px;
        width: fit-content;
        transition: transform 0.5s;
        cursor: pointer;

        h2 {
            margin: 0;
            font-family: $fontFamily;
            color: white;
            font-size: 22px;
        }

        &:hover {
            transform: scale(1.04);
        }

        &.disabled {
            filter: grayscale(100);

            &:hover {
                transform: none;
            }
        }

        &.logOut {
            background-color: $contrastColor;
            margin-top: 0;
            margin-bottom: 50px;

            h2 {
                font-size: 29px;
            }
        }
    }
}

@media(max-width: 550px) {
    .user {
        .container {
            .title {
                font-size: 40px;
            }
        }
    }
}