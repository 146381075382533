@import "/src/configurable/styles/variables.scss";

.infoBanner {
    width: calc(100vw - 40px);
    max-width: 1000px;
    height: 800px;
    max-height: calc(100% - 100px);
    font-family: $fontFamily;
    background: white;
    position: fixed;
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    border: 4px solid $primaryColor;
    border-radius: 20px;
    z-index: 10000;

    -webkit-box-shadow: 0px 0px 14px 3px rgba(0,0,0,0.32);
    -moz-box-shadow: 0px 0px 14px 3px rgba(0,0,0,0.32);
    box-shadow: 0px 0px 14px 3px rgba(0,0,0,0.32);

    .head {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        background: $primaryColor;
        padding: 0 40px 0 40px;
        height: 90px;
        border-radius: 10px 10px 0 0; 

        h1 {
            text-align: center;
            font-size: 40px;
            color: white;
            font-weight: 500;
        }

        img {
            height: 60px;
            width: auto;
            transition: transform 0.5s;

            &:hover {
                transform: scale(1.08);
            }
        }
    }

    .text {
        width: calc(100% - 40px);
        height: max-content;
        height: calc(100% - 140px);
        margin: 20px;
        overflow-y: scroll;
        color: $secondaryColor;
        position: relative;
        text-align: justify;
        padding-right: 8px;

        ul {
            position: absolute; 
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: max-content;
            max-width: 100%;
            text-align: left;

            li {
                font-size: 22px;
                margin: 20px;
            }
        }

        iframe {
            position: absolute; 
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: calc(100% - 30px);
            height: calc(100% - 30px);
        }
    }
}
  
.signUp {
    .infoBanner {
        height: 500px;
    }
}

@media (max-width: 650px) {
    .infoBanner {
        .head {
            height: 100px;

            h1 {
                text-align: center;
                font-size: 30px;
                color: white;
                font-weight: 500;
            }

            img {
                height: 45px;
                width: auto;
                transition: transform 0.5s;
                margin-left: 10px;

                &:hover {
                    transform: scale(1.08);
                }
            }
        }

        .text {
            ul {
                padding: 0;
            }
        }
    }
}