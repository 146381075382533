@import "/src/configurable/styles/variables.scss";

.documentViewer {
    margin: auto;
    margin-top: 40px;
    margin-bottom: 30px;
    width: fit-content;
    display: flex;
    justify-content: center;
    border: solid 2px $secondaryColor;
    width: calc(100vw - 200px);
    max-width: 1400px;
    height: calc(100vh - 100px);
    max-height: 1800px;
    background: white;
    overflow-x: scroll;
    position: relative;

    .react-pdf__Document {
        position: absolute;
        left: 0;
    }

    .slider {
        z-index: 100;
        position: fixed;
        bottom: 20px;
        width: 90%;
        max-width: 500px;
        border: solid 2px $contrastColor;
        -webkit-appearance: none;
        border-radius: 10px;
        height: 14px;
    }

    .slider::-webkit-slider-thumb, .slider::-moz-range-thumb {
        border: none;
        width: 25px;
        height: 25px;
        background: $primaryColor;
        cursor: pointer;
        border-radius: 12px;
    }
}

@media (max-width: 800px) {
    .documentViewer {
        width: 92%;    
    }
}
