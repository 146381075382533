
@import "/src/configurable/styles/variables.scss";

.signUp {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 200px);
    width: 100%;
    min-height: 830px;

    .container {
        width: calc(100% - 20px);
        max-width: 800px;
        min-height: 300px;
        border: solid 3px $secondaryColor;
        text-align: center;
        background: white;
        
        .title {
            font-family: $fontFamily;
            font-size: 50px;
            font-weight: lighter;
            color: $secondaryColor;
        }

        .button {
            background: $secondaryColor;
            margin: 30px auto 30px auto;
            padding: 7px 30px 7px 30px;
            width: fit-content;
            transition: transform 0.5s;
            cursor: pointer;

            h2 {
                margin: 0;
                font-family: $fontFamily;
                color: white;
                font-size: 22px;
            }

            &:hover {
                transform: scale(1.04);
            }

            &.disabled {
                filter: grayscale(100);

                &:hover {
                    transform: none;
                }
            }
        }

        .conf {
            display: flex;
            flex-direction: row;
            width: 100%;
            max-width: 500px;
            margin: auto;
            align-items: center;

            input {
                transform: scale(200%);
                margin: 20px;
            }

            h3 {
                position: relative;
                font-family: $fontFamily;
                font-size: 22px;
                color: $secondaryColor;
                font-weight: lighter;
                margin: 0;
                width: calc(100% - 20px);
                margin-right: 10px;
                line-height: 28px;
                text-align: left;
                overflow: hidden;
                height: 28px;
                white-space: nowrap;

                &::after {
                    background: white;
                    position: absolute;
                    right: 0;
                    content: "...";
                }

                &:hover {
                    overflow: auto;
                    height: auto;
                    white-space: normal;

                    &::after {
                        display: none;
                    }
                }
            }
        }
    }

    .diagram {
        background-image: url("../../../../public/images/pages/diagrams/Diagram-PC.png");
        height: 100%;
        width: 100%;
        background-repeat: no-repeat;
        background-size: contain;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);

        img {
            display: none;
        }
    }
}

@media (max-width: 1000px) {
    .signUp{
        .infoBanner{
            height: 800px;
            width: 600px;

            .diagram {
                background-image: url("../../../../public/images/pages/diagrams/Diagram-iPad.png");
            }
        }
    }
}

@media (max-width: 750px) {
    .signUp{
        .infoBanner{
            height: 670px;
            width: 500px;

            .diagram {
                background-image: url("../../../../public/images/pages/diagrams/Diagram-iPad.png");
            }
        }
    }
}


@media (max-width: 530px) {
    .signUp{
        .infoBanner{
            height: 670px;
            width: 350px;

            .text {
                overflow-y: scroll;

                .diagram {
                    background: none;

                    img {
                        width: 300px;
                        height: auto;
                        display: block;
                    }
                }
            }
        }
    }
}