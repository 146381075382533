@import "/src/configurable/styles/variables.scss";

.subject {
    width: 50%;
    min-width: 700px;
    max-width: 1200px;
    margin: auto;
    height: 240px;
    position: relative;
    background: $primaryColor50;

    h1 {
        font-size: 70px;
        font-weight: bold;
        color: white;
    }

    .commingSoon {
        position: absolute;
        z-index: 1000;
        right: -2%;
        top: 5%;
        width: 250px;
        height: 80px;
        border-radius: 20px;
        background: $contrastColor;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: rotate(10deg);
        box-shadow: 0px 0px 10px 3px rgba(0,0,0,0.20);

        h2 {
          font-size: 32px;  
          margin: 0;
          padding: 0;
        }
    }

    .contrast {
        background: $contrastColor;
        width: 250px;
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        clip-path: polygon(0 0, 100% 0, calc(100% - 170px) 100%, 0 100%);
        z-index: 20;
        display: flex;
        align-items: center;
        justify-content: center;
        overflow: hidden;
        transition: width 1s;

        img {
            height: 150px;
            width: auto;
        }
    }

    .main {
        width: 100%;
        height: 100%;
        position: relative;

        h1 {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            margin: 0;
        }

        .progressCircle {
            position: absolute;
            right: 40px;
            top: 50%;
            transform: translate(0, -50%);
            z-index: 20;
        }
    }

    &:hover {
        .contrast {
            width: 300px;
        }
    } 
}

.mobile.subject {
    width: 80%;
    min-width: 330px;
    max-width: 500px;
    height: 300px;
    margin: 30px auto 30px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;

    h1 {
        margin: 0;
        margin-bottom: 20px;
        line-height: 90px;
    }

    &::before,
    &::after {
        position: absolute;
        width: 40%;
        height: 300px;
        transition: width 1s;
        content: "";
    }

    &::after {
        left: 0;
        top: -5px;
        border-left: 5px solid $secondaryColor;
        border-top: 5px solid $secondaryColor;
    }

    &::before {
        right: 0;
        bottom: -5px;
        border-right: 5px solid $secondaryColor;
        border-bottom: 5px solid $secondaryColor;
    }

    &:hover {
        &::before,
        &::after {
            width: 60%;
        }
    }
}

.subject.disabled {
    &:hover {
        .contrast {
            width: 250px;
        }
    }
}