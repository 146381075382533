@import "/src/configurable/styles/variables.scss";

.product {
    border: solid 3px $secondaryColor;
    width: 380px;
    height: 630px;
    float: left;
    background: white;
    display: flex;
    justify-content: center;
    flex-direction: column;
    transition: transform 1s, filter 1.3s;

    &:hover:not(&.disabled) {
        transform: scale(1.02);
    }

    &.disabled {
        filter: grayscale(0.9);
    }

    .title {
        font-family: $fontFamily;
        font-size: 34px;
        font-weight: lighter;
        position: relative;
        width: 100%;
        text-align: center;
        color: $secondaryColor;

        &::after {
            content: "";
            position: absolute;
            width: 90%;
            height: 3px;
            background: $secondaryColor;
            top: 46px;
            left: 5%;
        }
    }

    .priceLine {
        width: 100%;
        position: relative;
        margin-top: 10px;

        h2 {
            font-size: 36px;       
            color: $primaryColor;
            font-family: $fontFamily;
            width: fit-content;
            margin: auto;
        }

        h2.cross {
            position: relative;

            &::after {
                content: "";
                width: 180px;
                height: 2px;
                background: black;
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%) rotate(-14deg);
            }
        }

        .sale {
            width: 100px;
            height: 100px;
            border-radius: 50%;
            background: $contrastColor;
            color: white;

            display: flex;
            justify-content: center;
            align-items: center;

            position: absolute;
            right: 0;
            top: 0;
            transform: translate(25%, 5%);

            h3 {
                line-height: 26px;
                text-align: center;
                font-size: 24px;
                font-family: $fontFamily;
                margin: 0;
            }
        }
    }

    .image {
        width: 100%;
        margin-top: 16px;
    };

    .button {
        width: fit-content;
        padding: 8px 20px 8px 20px;
        margin: 0 auto 20px auto;
        font-size: 20px;
        color: white;
        background: $primaryColor;

        h2 {
            margin: 0;
        }

        &:hover:not(.product.disabled .button) {
            animation: buttonHover 2s ease-in-out infinite;
        }
    }

    @keyframes buttonHover {
        0%   {transform: translate(0)}
        25%  {transform: translate(4px, -5px) rotate(3deg) ;}
        50%  {transform: translate(0);}
        75%  {transform: translate(-4px, -5px) rotate(-3deg) ;}
        100% {transform: translate(0);}
    }
}

@media (max-width: 450px) {
    .product {
        .title {
            font-size: 28px;
        }
    }
}