@import "/src/configurable/styles/variables.scss";

.home {
    min-height: calc(100vh - 200px);
    width: 100%;
    
    .headBlock {
        display: flex;
        flex-direction: row;
        justify-content: center;
        height: 620px;
        padding: 60px;
        width: 100%;
        background: rgb(51,101,128);
        background: linear-gradient(90deg, rgba(51,101,128,1) 45%, rgba(0,212,255,0) 100%);

        .text {
            color: white;
            font-family: $fontFamily;   
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 50%;
            max-width: 600px;
            padding: 35px 0 35px 0;

            h1 {
                font-size: 40px;
                margin: 0;
                line-height: 70px;
                font-family: $fontFamily;   
            }

            .block {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-top: 20px;

                h2 {
                    font-size: 18px;
                    font-weight: lighter;
                    max-width: 500px;
                }

                .button {
                    padding: 20px 40px;
                    border-radius: 40px;
                    background: $contrastColor;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: fit-content;
                    margin-top: 40px;
                    align-self: center;
                    position: relative;
                    overflow: hidden;
                    transition: transform 0.6s;
                    
                    p {
                        margin: 0;
                        padding: 0;
                        font-size: 24px;
                        z-index: 100;
                    }

                    &::after {
                        content: "";
                        left: 0;
                        top: 0;
                        width: 150%;
                        height: 200%;
                        position: absolute;
                        background: $primaryColor;
                        transition: transform 0.6s;
                        transform: translate(-100%) skew(20deg);
                    }

                    &:hover {
                        transform: scale(1.02);

                        &:after {
                            transform: translate(-65%) skew(10deg);
                        }    
                    }
                }
            }
        }

        .image {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            max-width: 600px;
            
            img {
                height: 700px;
            }
        }
    }

    .header {
        font-size: 44px;
        color: $secondaryColor;
        text-align: center;
        padding: 20px;
    }

    .textBlock {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: white;

        .row {
            display: flex;
            flex-direction: row-reverse;
            max-width: 1500px;
            width: calc(100% - 60px);
            position: relative;

            &:nth-of-type(odd) {
                padding-bottom: 120px;
                flex-direction: row;

                .text {
                    padding-left: 70px;
                    padding-right: 0;
                }
            }

            .image {
                width: 50%;
                overflow: hidden;
                border-radius: 20px;
                
                img:not(.cat) {
                    min-width: 100%;
                    min-height: 100%;
                }

                .cat {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 200px;
                    transform: translate(-25px, -45px);
                }
            }

            .text {
                width: 50%;
                color: $secondaryColor;
                font-family: $fontFamily;
                padding-right: 50px;
                text-align: justify;

                p {
                    font-size: 16px;
                }

                h2 {
                    font-size: 21px;
                }
            }
        }
    }

    .linesHolder {
        width: 100%;
        background-color: white;
        padding: 80px;

        .lines {
            max-width: 1300px;
            width: calc(100% - 60px);
            display: flex;
            flex-direction: row;
            margin: auto;
            justify-content: space-between;

            img {
                transition: transform 0.6s;

                &:hover {
                    transform: scale(1.08);
                }
            }
        }
    }

    .social {
        width: 100%;
        //background-color: white;
        margin-top: -30px;
        margin-bottom: -20px;

        .postLine {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            overflow: hidden;
            justify-content: space-between;
            height: 500px;
            max-width: 1500px;
            width: calc(100% - 60px);
            margin: auto;
            position: relative;
            
            .post {
                height: 300px;
                width: 300px;
                margin: 5px;
                border-radius: 30px;
                overflow: hidden;

                img {
                    transition: transform 1s;
                    width: 100%;
                }

                &:hover {
                    img {
                        transform: scale(1.10);
                    }
                }
            }

            .penguin {
                position: absolute;
                right: 0;
                bottom:0;
                width: 180px;

                transform: translate(7px, -150px);
            }
        }
    }
}

@media (max-width: 1320px) {
    .home {
        .social {
            .postLine {
                max-width: 1100px;

                .post:nth-last-of-type(1) {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 1150px) {
    .home {
        .linesHolder {
            padding-left: 30px;
            padding-right: 30px;

            .lines {
                max-width: 100%;
                width: 100%;
            }
        }
    }
}

@media (max-width: 1000px) {
    .home {
        .linesHolder {
            padding-left: 30px;
            padding-right: 30px;
            position: relative;
            height: 260px;

            .lines {
                width: 360px;
                display: block;
                position: absolute;
                left: 50%;
                transform: translate(-50%);
                
                img {
                    width: auto;
                    height: 40px;
                    margin-bottom: 15px;
                }
            }
        }

        .social {
            .postLine {
                max-width: 700px;

                .post:nth-last-of-type(2) {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 940px) {
    .home {
        .textBlock {
            align-items: center;
            background-color: white;

            .row {
                flex-direction: column;
                width: calc(100% - 60px);
                position: relative;

                &:nth-of-type(odd) {
                    flex-direction: column;
                }

                .image {
                    width: 100%;
                    overflow: visible;
                    height: auto;
                    position: relative;

                    &::after {
                        content: '';
                        height: 100%;
                        position: absolute;
                        left: 100%;
                        width: 100px;
                        background-color: white;
                    }

                    .cat {
                        right: 0;
                        left: auto;
                        transform: translate(40px, 40px);
                        z-index: 100;
                    }
                }

                .text {
                    width: 100%;
                    padding: 0 !important;
                }
            }
        }

        .headBlock {
            flex-direction: column-reverse;
            height: 1200px;
            background: linear-gradient(0deg, rgba(51,101,128,1) 30%, rgba(0,212,255,0) 100%);
            align-items: center;

            .text {
                width: 100%;

                h1 {
                    font-size: 40px;
                    text-align: center;
                }

                .block {
                    width: 100%;
                    display: flex;
                    align-items: center;
                    padding-top: 60px;

                    h2 {
                        display: none;
                    }
                }
            }
        }
    }
}

@media (max-width: 680px) {
    .home { 
        .social {
            .postLine {
                flex-direction: column;
                flex-wrap: nowrap;
                height: auto;
                align-items: center;
                width: 100%;
                padding: 0 20px 100px 20px;

                .post:nth-last-of-type(2) {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 500px) {
    .home {
        .headBlock {
            padding: 20px;

            .text {
                h1 {
                    font-size: 30px;
                }
            }

            .image {
                img {
                    height: 600px;
                }
            }
        }

        .linesHolder {
            padding-left: 30px;
            padding-right: 30px;
            position: relative;
            height: 260px;

            .lines {
                width: 300px;
                
                img {
                    width: auto;
                    height: 34px;
                    margin-bottom: 15px;
                }
            }
        }

        .social {
            .postLine {
                .penguin {
                    width: 120px;
                    transform: translate(7px, -100px);
                }
            }
        }
    }
}