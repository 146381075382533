@import "/src/configurable/styles/variables.scss";

.formField {
    display: flex;
    position: relative;

    flex-direction: row;
    margin: auto;
    max-width: 500px;
    margin-bottom: 20px;
    padding: 7px;

    h3 {
        font-family: $fontFamily;
        font-size: 22px;
        color: $secondaryColor;
        font-weight: lighter;
        margin: 0;
        margin-right: 10px;
        width: 35%;
        line-height: 28px;
        min-width: fit-content;
        text-align: left;
    }

    div {
        width: 70%;
        position: relative;

        input {
            border: solid 2px $secondaryColor;
            height: 28px;
            margin: 0;
            width: 100%;
            transition: transform 0.6s;
            color: $terciaryColor;
            padding-left: 5px;

            &:focus-visible {
                outline: none;
                transform: scale(1.04);
            }
        }

        .eye {
            position: absolute;
            right: 0;
            top: 50%;
            height: 18px;
            transform: translate(-50%, -50%);
            z-index: 100;
        }
    }
}

@media (max-width: 600px) {
    .formField {
        flex-direction: column;
        padding: 0 20px 0 20px;

        h3 {
            width: 100%;
        }

        div{
            width: 100%;

            input {
                width: 100%;
            }
        }
    }
}
