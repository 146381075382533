@import "/src/configurable/styles/variables.scss";

.menu {
    width: 100%;
    height: 100px;
    background: $primaryColor;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 30px 0 30px;
    overflow: hidden;
    position: relative;

    .logo {
        height: 67px;
        transition: transform 1s;

        &:hover {
            transform: scale(1.03);
        }
    }

    .logo.mobile {
        height: 50px;
    }

    .links:not(.mobile) {
        display: flex;
        justify-content: space-between;
        width: 400px;
        align-items: center;
        height: 100%;

        a {
            font-family: $fontFamily;
            text-decoration: none;
            color: white;
            font-size: 30px;
            transition: transform 1s;

            &:hover {
                transform: scale(1.04);
            }

            &:last-of-type {
                font-size: 36px;
                font-weight: bold;
                position: relative;

                p {
                    z-index: 3;
                    position: relative;
                }

                &::before {
                    content: "";
                    position: absolute;
                    border-radius: 50%;
                    height: 400px;
                    width: 400px;
                    top: -220px;
                    right: -255px;
                    background: $contrastColor;
                }
            }
        }
    }

    .button {
        width: 95px;
        padding: 30px;
        height: auto;
        transition: transform 1s;
    }

    .button.invisible {
        transform: rotate(180deg);
    }

    .links.mobile {
        display: flex;
        position: absolute;
        top: 100px;
        right: 12px;
        z-index: 200;
        max-width: 400px;
        width: 100%;
        background: $primaryColor;
        border-radius: 0 0 20px 20px;
        transition: transform 1s;
        flex-direction: column;
        z-index: -2;
        border: solid 2px white;
        border-top: none;

        a {
            font-family: $fontFamily;
            text-decoration: none;
            color: white;
            font-size: 30px;
            transition: transform 1s;
            height: 100px;
            display: flex;
            justify-content: center;
            align-items: center;

            &:hover {
                transform: scale(1.04);
            }

            &:last-of-type {
                font-size: 36px;
                font-weight: bold;
                position: relative;
                background: $contrastColor;
                border-radius: 100px;
                margin: 10px;

                p {
                    z-index: 3;
                    position: relative;
                    margin: 0;
                }
            }
        }
    }

    .links.mobile.invisible {
        transform: translate(0, -100%);
    }
}

.menu.mobile {
    overflow: visible;
    z-index: 1000;

    &::after {
        content: "";
        position: absolute;
        width: 120%;
        margin-left: -10%;
        height: 100px;
        background: $primaryColor;
        z-index: -1;
    }
}

@media (max-width: 550px) {
    .menu {
        padding: 0 12px 0 12px;
        
        .logo.mobile {
            height: 40px;
        }

        .links.mobile {
            width: calc(100% - 20px);
            max-width: none;

            a {
                font-size: 24px;
                
                &:last-of-type {
                    font-size: 27px;
                }
            }
        }
    }
}
