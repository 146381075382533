@import "/src/configurable/styles/variables.scss";

.progressCircle {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    h2 {
        margin: 0;
        text-align: center;
        font-family: $fontFamily;
        font-size: 40px;
        color: $secondaryColor;
        font-weight: 500;
        background: transparent;
    }

    img {
        height: 75%;
        width: auto;
    }
}
  