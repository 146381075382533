@import "/src/configurable/styles/variables.scss";

.heading {
    width: 90%;
    max-width: 800px;
    height: 150px;
    background: $primaryColor50;
    position: relative;
    margin: auto;
    margin-bottom: 20px;
    margin-top: 80px;

    &::before {
        content: "";
        position: absolute;
        left: -10px;
        top: -10px;
        height: 135px;
        width: 80px;
        border-top: solid 10px $secondaryColor;
        border-left: solid 10px $secondaryColor;
    }

    &::after {
        content: "";
        position: absolute;
        right: -10px;
        bottom: -10px;
        height: 135px;
        width: 80px;
        border-bottom: solid 10px $secondaryColor;
        border-right: solid 10px $secondaryColor;
    }


    h1 {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        margin: 0;
        color: white;
        font-size: 50px;
        font-weight: bolder;
        font-family: $fontFamily;
    }

    .progressCircle {
        height: 120px;
        width: 120px;
        position: absolute;
        top: 50%;
        right: 5%;
        transform: translate(0, -50%);
    }
}

@media (max-width: 750px) {
    .progressCircle {
        display: none;
    }
}

.subjectPage0 {
    width: calc(100% - 15px);
    height: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .lesson {
        margin: 30px 0 30px 0;
        position: relative;
        z-index: auto !important;

        &::before {
            content: "";
            position: absolute;
            height: 100px;
            width: 10px;
            top: 100%;
            left: calc(50% - 5px);
            background: $secondaryColor;
        }

        &:last-of-type {
            &::before {
                display: none;
            }
        }
    };
}

.subjectPage1 {
    max-width: 1050px;
    width: 90%;
    margin: auto; 
    display: flex;
    flex-direction: column;

    .lesson {
        margin: 50px 0 50px 0;
        position: relative;

        &::before {
            content: "";
            position: absolute;
            height: 255px;
            width: calc(90%);
            bottom: 100%;
            border-top: solid 10px $secondaryColor;
        }

        &:first-of-type {
            &::before {
                display: none;
            }
        }

        &:nth-of-type(2n - 1) {
            &::before {
                left: calc(50% - 5px);
                border-left: solid 10px $secondaryColor;
            }
        }

        &:nth-of-type(2n) {
            align-self: flex-end;

            &::before {
                right: calc(50% - 5px);
                border-right: solid 10px $secondaryColor;
            }
        }
    }
}

.subjectPage2 {
    width: 1830px;
    margin: auto;
    display: table;

    .lesson {
        margin: 50px 30px 50px 30px;
        float: left;
        z-index: auto !important;

        &::before {
            content: "";
            width: 200px;
            height: 12px;
            background: $secondaryColor;
            position: absolute;
            top: calc(50% - 6px);
            left: 100%;
        }

        &:nth-of-type(6n),
        &:nth-of-type(6n - 1),
        &:nth-of-type(6n - 2) {
            float: right;

            &::before {
                right: 100%;
                left: auto;
            }
        }

        &:nth-of-type(3n) {
            &::before {
                left: calc(50% - 6px);
                top: 100%;
                width: 12px;
                height: 200px
            }
        }

        &:last-of-type {
            &::before {
                display: none;
            }
        }
    }
}
