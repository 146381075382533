@import "/src/configurable/styles/variables.scss";

.sale {
    display: flex;
    justify-content: center;
    padding: 30px;
    flex-flow: row wrap;
    overflow: hidden;

    .product {
        margin: 30px;

        .children {
            font-family: $fontFamily;
            font-size: 18px;
            color: $secondaryColor;

            ul {
                list-style-type: "- ";
            }
        }
    }

    .cart {
        border: solid 6px $contrastColor;
        border-radius: 20px 20px 0 0; 
        border-bottom: none;
        position: relative;
        max-width: 800px;
        width: calc(100vw - 15px);
        padding: 25px;
        background: white;
        color: $secondaryColor;
        font-family: $fontFamily;
        padding-bottom: 50px;
        position: fixed;
        bottom: 0;
        z-index: 1000;
        transform: translate(0, 100%);
        transition: transform 2s;

        &:hover, &.open {
            transform: translate(0);
        }

        .tag {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translate(-50%, -100%);
            height: 120px;
        }

        .tag.bounce {
            animation: bounce 4s;
        }

        @keyframes bounce {
            0% {transform: translate(-50%, -100%) scale(1);}
            10% {transform: translate(-50%, -100%) scale(1.1);}
            30% {transform: translate(-50%, -100%) scale(1);}
            40% {transform: translate(-50%, -100%) scale(1.08);}
            60% {transform: translate(-50%, -100%) scale(1);}
            70% {transform: translate(-50%, -100%) scale(1.06);}
            100% {transform: translate(-50%, -100%) scale(1);}
        }

        h1 {
            text-align: center;
            font-size: 42px;
            margin: 0;
            font-weight: lighter;
        }

        .holder {
            position: relative;
            width: 100%;
            max-height: 70vh;
            overflow-y: scroll;
            overflow-x: hidden;

            .products {
                margin-bottom: 50px;

                .empty {
                    text-align: center;
                    font-weight: lighter;
                    color: $contrastColor;
                    margin: 30px auto 30px auto;
                }

                .line {
                    display: flex;
                    flex-direction: row;
                    margin: 14px;

                    h2 {
                        font-weight: lighter;
                        font-size: 22px;
                        line-height: 30px;
                        margin: 0;
                    }

                    .spacer {
                        flex-grow: 1;
                        border-bottom: dotted 3px $secondaryColor;
                        height: 0;
                        margin: 20px 3px 0 3px;
                    }

                    img {
                        height: 30px;
                        margin-left: 10px;
                    }
                }

                hr {
                    height: 5px;
                    border-radius: 2px;
                    margin: 20px 0 20px 0;
                    background: $secondaryColor;
                    border: none;
                }
            }
        }

        #payment-element {
            margin-bottom: 30px;
        }

        .pay {
            width: calc(100% - 10px);
            height: 50px;
            border-radius: 25px;
            margin: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            font-size: 24px;
            background-color: $secondaryColor;
            border: solid 3px $secondaryColor;
            color: white;
            transition: transform 0.5s;
            max-width: 300px;

            &:not(.disabled):hover {
                transform: scale(1.04);
            }

            &:disabled {
                background-color: #6d8496;
            }
        }
    }
}

@media (max-width: 500px) {
    .sale {
        padding-left: 20px;
        padding-right: 20px;

        .product {
            margin: 20px auto 20px auto;
        }
    }
}

