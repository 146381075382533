@font-face {
    font-family: "DejaVu Sans Mono";
    src: local("DejaVu Sans Mono"),
      url("./fonts/DejaVuSansMono.ttf") format("truetype");
}

.app {
    height: 100%;
    min-height: 100vh;
    position: relative;
    width: 100%;
    padding-bottom: 110px;
}

html {
    width: 100vw;
    overflow-x: hidden;
}

body {
    margin: 0;
    background-image: url("../public/images/common/background.png");
    background-repeat: repeat;
    min-height: auto !important;
    width: 100vw;
    overflow-x: hidden;
}

.back {
    position: fixed;
    left: 20px;
    bottom: 40px;
    z-index: 2000;

    img {
        height: 90px;
        width: auto;
    }

    &:hover {
        animation: anim 1.2s ;
        animation-iteration-count: infinite;
    }
}

@media (min-width: 500px) {
    .back {
        img {
            height: 120px;
            width: auto;
        }
    }
}

@keyframes anim {
    0%   { transform: rotate(0); }
    50%   { transform: rotate(-12deg); }
    100%   { transform: rotate(0); }
}