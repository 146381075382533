@import "/src/configurable/styles/variables.scss";

.lessonPage {
    width: 90%;
    max-width: 1000px;
    margin: auto;
    display: table;

    .title {
        height: 160px;
        padding: 5px;
        width: 100%;
        border: solid 3px $secondaryColor;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background: white;
        margin: 70px 0 70px 0;
        transition: transform 0.7s;

        &:hover {
            transform: scale(1.015);
        }

        &::before {
            content: "";
            position: absolute;
            left: -10px;
            top: -10px;
            height: 135px;
            width: 80px;
            border-top: solid 10px $secondaryColor;
            border-left: solid 10px $secondaryColor;
        }

        &::after {
            content: "";
            position: absolute;
            right: -10px;
            bottom: -10px;
            height: 135px;
            width: 80px;
            border-bottom: solid 10px $secondaryColor;
            border-right: solid 10px $secondaryColor;
        }

        h1 {
            margin: 0;
            font-size: 50px;
            font-family: $fontFamily;
            color: $secondaryColor;
        }

        img {
            z-index: 20;
            position: absolute;
            right: 0;
            top: 0;
            height: 90px;
            width: 90px;
            transform: translate(30%,-40%);
        }
    }

    .block {
        width: 100%;
        height: auto;
        overflow: hidden;
        margin: 30px 0 80px 0;
        max-height: 100%;
        transition: max-height 1s;
        
        .head {
            width: 100%;
            height: 110px;
            background: $primaryColor;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0 50px 0 50px;
            z-index: 2;
            position: relative;
            transition: transform 1s;
            float: left;

            h1 {
                font-family: $fontFamily;
                color: white;
                font-size: 40px;
                font-weight: lighter;
            }

            img {
                height: 45px;
                width: auto;
                transition: transform 0.35s;
            }

            &:hover {
                transform: scale(1.02);
            }
        }

        .lines {
            width: 100%;
            z-index: 0;
            position: relative;
            transition: transform 1.5s;

            .line {
                width: 100%;
                background: white;
                border: solid 3px $primaryColor;
                border-top: none;
                height: 90px;
                display: flex;
                align-items: center;

                img {
                    height: 45px;
                    width: auto;
                    margin: 0 20px 0 30px;
                }

                h2 {
                    font-family: $fontFamily;
                    font-weight: lighter;
                    font-size: 26px;
                    background-image: linear-gradient(
                        to right,
                        black,
                        black 50%,
                        $secondaryColor 50%
                    );

                    background-size: 200% 100%;
                    background-position: -100%;
                    -webkit-background-clip: text;
                    -webkit-text-fill-color: transparent;
                    margin: 0;
                    transition: all 0.4s;
                }

                &:hover {
                    h2 {
                        background-position: 0;
                    }
                }
            }
        }
    }
}

.block.collapsed {
    margin: 30px 0 0px 0;
    max-height: 110px;

    .lines {
        transform: translate(0, -100%);
    }

    .head {
        img {
            transform: rotate(180deg);
        }
    }
}

@media (max-width: 800px) {
    .lessonPage {
        .title {
            h1 {
                font-size: 45px;
            }
        }
    }
}

@media (max-width: 600px) {
    .lessonPage {
        .title {
            h1 {
                font-size: 35px;
            }
        }

        .block {

            .head {
                padding: 0 20px 0 20px;

                h1 {
                    font-size: 30px;
                }
            }
            
            .lines {
                .line {
                    h2 {
                        font-size: 21px;
                    }

                    img {
                        margin: 0 12px 0 20px;
                    }
                }
            }
        }
    }
}

@media (min-width: 1800px) {
    .lessonPage {
        width: 1720px;
        max-width: none;

        .title {
            max-width: 1300px;
            margin: 50px auto 30px auto;
        }

        .block{
            width: 800px;
            margin: 30px;
            float: right;
        }
    }
}