@import "/src/configurable/styles/variables.scss";

.signIn {
    display: flex;
    align-items: center;
    justify-content: center;
    height: calc(100vh - 200px);
    min-height: 550px;
    width: 100%;

    .container {
        width: calc(100% - 20px);
        max-width: 800px;
        min-height: 300px;
        border: solid 3px $secondaryColor;
        text-align: center;
        background: white;
        
        .title {
            font-family: $fontFamily;
            font-size: 50px;
            font-weight: lighter;
            color: $secondaryColor;
        }

        .button {
            background: $secondaryColor;
            margin: 30px auto 30px auto;
            padding: 7px 30px 7px 30px;
            width: fit-content;
            transition: transform 0.5s;
            cursor: pointer;

            h2 {
                margin: 0;
                font-family: $fontFamily;
                color: white;
                font-size: 22px;
            }

            &:hover {
                transform: scale(1.04);
            }
        }
    }
}
