@import "/src/configurable/styles/variables.scss";

.videoPlayer {
    margin-top: 40px;
    margin-bottom: 30px;
    height: calc(100vh - 150px);
    min-height: 780px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    .player {
        margin: auto;
        width: calc(100% - 15px) !important;
        max-width: 1200px;
        max-height: 800px;
        min-height: 300px;
    }
}