@import "/src/configurable/styles/variables.scss";

.learningSpace {
    margin-top: 40px;
    margin-bottom: 30px;
    height: calc(100vh - 150px);
    min-height: 780px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}